
































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import Form from "@/pages/company/Form";
// import ExportForm from "@/pages/company/ExportForm.vue";
import formatter from "@/mixins/formatter";
import { Export } from "@/services/xlsx.ts";
import ImportEditor from "@/components/ImportEditor";
import GridView from "@/components/GridView";
import Card from "@/components/Card";
import { setPageTitle } from "@/utils/meta";
import { AuthUser } from "@/smartmsi";
import { State } from "vuex-class";

@Component({
  components: { Card, GridView, Form, ImportEditor },
  mixins: [formatter],
})
export default class CompanyIndex extends Vue {
  @State user!: AuthUser;

  created() {
    setPageTitle("Companies");
    this.loadCountries();
    this.loadCompanies();
    this.loadSectors();
    this.loadAvailableYears();
    this.loadAvailableYearsSmart();
  }

  editDialog = false;
  editedCompany = null;
  uploadedFile = null;
  companies = []
  sectors = [];
  importing = false;
  countries = []
  filterDebounce!: NodeJS.Timeout;
  countryFilterId = null;
  sectorId = null;
  availableYears = null
  availableYearsIds = null
  availableYearsSmart = null
  availableYearsSmartIds = null

  endpoint = "company?expand=country,legalTypology,companyActivities.activity.sector,datum,datumSmart"
  exportInfo = {
    "endpoint": this.endpoint,
    "totalCount": 0,
    "pageCount": 0,
    "currentPage": 0,
    "perPage": 25
  }

  get headers() {
    return [
      { text: "", value: "_actions" },
      { text: this.$i18n.t("company.denomination"), value: "name", width: "100px" },
      {
        text: this.$i18n.t("ISIN"),
        value: "profil_fmp.isin",
        width: 1,
      }, {
        text: this.$i18n.t("models.company.symbol"),
        value: "symbol",
        width: 1,
      },
      {
        text: this.$i18n.t("models.company.country"),
        value: "country.name",
        width: 1,
      },
      {
        text: this.$i18n.t("availableYearsFull"),
        value: "availableYearsFull",
        width: 190,
        sortable: false,
        filterable: false
      },
      {
        text: this.$i18n.t("availableYearsSmart"),
        value: "availableYearsSmart",
        width: 190,
        sortable: false,
        filterable: false
      },
      {
        text: this.$i18n.t("sectorsOfActivity.title"),
        value: "companyActivities",
      },
      {
        text: this.$i18n.t("origin"),
        value: "origin",
      },

    ];
  }
  handleDataLoaded(data) {
    this.companies = data; // Update the companies data with the received data from GridView
  }
  handleEndpointUpdated(apiInfo) {
    console.log('apiInfo:', apiInfo);
    // this.endpoint = newEndpoint; // Update the parent's `endpoint` or take any other action
  }

  viewCompany(company) {
    this.$router.push(`/company/${company.id}`);
  }
  loadCountries() {
    return this.$api.get(`country`).then(res => {
      this.countries = res.data;
      // console.log("countrys",res.data)
    });
  }
  loadSectors() {
    return this.$api.get(`sector`).then(res => {
      this.sectors = res.data;
      // console.log("countrys",res.data)
    });
  }
  loadAvailableYears() {
    return this.$api.get(`company/available-years`).then(res => {
      this.availableYears = res.data;
      // console.log("countrys",res.data)
    });
  }
  loadAvailableYearsSmart() {
    return this.$api.get(`company/available-years-smart`).then(res => {
      this.availableYearsSmart = res.data;
      // console.log("countrys",res.data)
    });
  }
  onChangeCountry(selectedItem: any) {
    if (selectedItem) {
      // this.loadingData = true;
      this.countryFilterId = selectedItem;
      console.log("countries", this.countryFilterId)
    }
  }
  onChangeSector(selectedItem: any) {
    if (selectedItem) {
      // this.loadingData = true;
      this.sectorId = selectedItem;
    }
  }
  onChangeAvailableYears(selectedItem: any) {
    if (selectedItem) {
      // this.loadingData = true;
      this.availableYearsIds = selectedItem;
    }
  }
  onChangeAvailableYearsSmart(selectedItem: any) {
    if (selectedItem) {
      // this.loadingData = true;
      this.availableYearsSmartIds = selectedItem;
    }
  }

  @Watch("countryFilterId")
  @Watch("sectorId")
  @Watch("availableYearsIds")
  @Watch("availableYearsSmartIds")
  onFilterChanged() {
    clearTimeout(this.filterDebounce);
    this.filterDebounce = setTimeout(() => this.loadCompanies(), 600);
  }

  buildFilterString(): string {
    let filterString = '';

    if (this.countryFilterId) {
      filterString += `&filter[country.id]=${this.countryFilterId}`;
    }
    // Handle multiple selected country
    if (this.countryFilterId && this.countryFilterId.length > 0) {
      this.countryFilterId.forEach((id: number) => {
        filterString += `&filter[country.id][in][]=${id}`;
      });
    }
    if (this.sectorId && this.sectorId.length > 0) {
      // Join the sector IDs with a comma
      const joinedIds = this.sectorId.join(',');
      filterString += `&filter[sector.id][in][]=${joinedIds}`;
    }
    if (this.availableYearsIds && this.availableYearsIds.length > 0) {
      // Join the sector IDs with a comma
      const joinedIds = this.availableYearsIds.join(',');
      filterString += `&filter[datum.year][in][]=${joinedIds}`;
    }
    if (this.availableYearsSmartIds && this.availableYearsSmartIds.length > 0) {
      // Join the sector IDs with a comma
      const joinedIds = this.availableYearsSmartIds.join(',');
      filterString += `&filter[datumSmart.year][in][]=${joinedIds}`;
    }
    return filterString;
  }
  loadCompanies() {
    const filterString = this.buildFilterString();

    this.endpoint = `company?expand=country,legalTypology,companyActivities.activity.sector,datum,datumSmart&per-page=50&sort=-score${filterString}`
  }
  // Debounce function to limit the rate of execution
  debounce(func: Function, delay: number) {
    let timeout: number | undefined;
    return (...args: any[]) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  activateCompany(company) {
    let active = 'désactiver';
    let activeVal = 1;
    if (company.enabled == 1) {
      activeVal = 0;
      active = 'désactiver';
    } else {
      activeVal = 1;
      active = 'activer';
    }

    this.$root.$confirm
      .open(
        "Activation portfolio",
        "Êtes-vous sûr(e) de vouloir " + active + " cette société ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api.post(`company/active-company?id=${company.id}&status=${activeVal}`).
            then(response => {
              this.$refs.grid.getModels();
            }).catch((e) => {
              this.error = e.response.data;
            })
        }
      });
  }
  createCompany() {
    this.$router.push("/company/create");
  }

  truncateText(text, length = 30) {
    if (text.length <= length) {
      return text;
    }
    return text.slice(0, length) + '...';
  }

  isTruncated(text, length = 30) {
    return text.length > length;
  }

  deleteCompany(company) {
    this.$root.$confirm
      .open(
        "Delete company",
        "Are you sure you want to delete this company ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api
            .delete(`company/${company.id}`)
            .then(() => {
              this.$refs.grid.getModels();
            })
            .catch(() => {
              alert("Unable to remove company");
            });
        }
      });
  }

  onDialogSave(company) {
    this.$refs.grid.getModels(company.id);
    this.editDialog = false;
    this.editedCompany = null;
  }
  toogleDialog() {
    this.editDialog = true;
  }
  importXlsx() {
    console.log(this.uploadedFile);
  }

  xls() {
    Export("company", this.companies, "companies.xlsx");
  }
}
